@import 'variables';

.form-section {
	background-color: $body-bg;
	padding: 40px 0px 90px 0px;
	input + p {
	    margin-top: 5px;
	}
	input.formText[aria-required="true"] {
	    background: #facfce;
	}
	input.formText {
	    box-sizing: border-box;
	    width: 100%;
	    padding: 10px 15px;
	    margin: 0;
	    word-wrap: break-word;
	    border: 1px solid #e6e6e6;
	    border-radius: 2px;
	    &::placeholder {
	    	color: #7b737a;
	    }
	}
	.validationText {
	    color: #e4501d;
	    background-color: #f8eeee;
	    padding: 5px 10px;
	    margin-bottom: 0px;
	}
	.pwDecision {
		margin-left: 14px;
		p {
			margin: 0px;
			img {
			    width: 14px;
			    vertical-align: middle;
			    margin-right: 5px;
			}
		}
	}
	.checkboxFrame {
		margin: 5px auto;
		label {
		    position: relative;
		    display: block;
		    padding: 15px 10px 14px 40px;
		    margin-bottom: 0;
		    overflow: hidden;
		    line-height: 1.7;
		    text-overflow: ellipsis;
		    cursor: pointer;
		    background-color: #f5f5f5;
			width: 240px;
			&:before {
			    position: absolute;
			    top: 18px;
			    left: 15px;
			    width: 13px;
			    height: 13px;
			    content: '';
			    border: 2px solid #cccccc;
			    background-color: #fff;
			    box-sizing: content-box;
			}
			&:hover {
			    background-color: #fafafa;
			}
			&:hover:before {
				border-color: #ffc700;
    			background-color: #fff;
			}
			&:active:before {
				border-color: #ffc700;
    			background-color: #fff;
			}
		}
		input[type=checkbox] {
			display: none;
			&:checked + label {
			    background-color: #fbe7cb;
			    opacity: 1;
			    cursor: pointer;
			    &:before {
				    border-color: #ffc700;
				    background-color: #ffc700;
				    background-image: url(../assets/img/icon/checkbox_on.png);
				    background-position: 14px 15px;
				    opacity: 1;
				    cursor: pointer;
				}
			}
		}
	}
	.submitButton {
		display: block;
	    color: #fff;
	    width: 280px;
        max-width: 100%;
	    cursor: pointer;
	    font-size: 14px;
	    text-align: center;
	    background-color: #ed9c26;
	    border-radius: 5px;
	    border: none;
	    margin: 30px auto 0;
	    padding: 15px 20px;
	    &:disabled {
		    background: #999;
		}
	}
	.radioBtnFrame {
		label {
		    position: relative;
		    display: block;
		    float: left;
		    width: 49%;
		    padding: 15px 10px 14px 40px;
		    margin: 0 1% 0 0;
		    overflow: hidden;
		    line-height: 1.7;
		    text-overflow: ellipsis;
		    cursor: pointer;
		    background-color: #f5f5f5;
		    &::before {
			    position: absolute;
			    top: 18px;
			    left: 15px;
			    width: 13px;
			    height: 13px;
			    content: "";
			    background-color: #fff;
			    border: 2px solid #ccc;
			    border-radius: 13px;
			    box-sizing: content-box;
			}
			&:focus, &:hover {
			    background-color: #fafafa;
			}
		}
		input:checked+label {
		    background-color: #fff9e5;
		    &::before {
			    background-color: #ed9c26;
			    background-position: 14px;
			    border-color: #ed9c26;
			}
		}
		input[type="radio"] {
		    display: none;
		}
	}
}
.flameBtnBeforeCheckbox {
	.checkboxFrame {
		label {
			width: 100%;
		}
	}
}
.textLinkOrange {
    text-decoration: underline;
    color: #ed9c26;
    cursor: pointer;
    &:hover {
	    opacity: 0.8;
	    color: #ed9c26;
	}
}
.box-white {
	box-sizing: border-box;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0,0,0,0.15);
    .box-row {
    	padding: 0px 20px;
    	&:not(:last-child) {
    		.box-input {
	    		border-bottom: 1px solid #f0efef;
	    	}
    	}
    	.box-input {
    		padding-top: 15px;
    		padding-bottom: 15px;
    	}
	}
}
.form-title {
	color: #3f3f3f;
	font-size: 27px;
	font-weight: 400;
}
.input-label {
	padding: 30px 15px 15px 0;
    margin: 0;
    font-size: 14px;
}
.requiredText {
    display: inline-block;
    padding: 2px 4px;
    margin-left: 10px;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    vertical-align: text-bottom;
    background-color: #d00;
    border-radius: 2px;
}
.box-alert-danger {
	background:#facfce;
	color:#e4501d;
	text-align: center;
	padding: 10px;
	margin-top: 30px;
    margin-bottom: 28px;
}
.danger-link {
	color:#e4501d;
	text-decoration:underline;
}

.btnSignup {
	a {
	    display: block;
	    color: #fff;
	    width: 280px;
	    font-size: 14px;
	    text-align: center;
	    background-color: #ce5468;
	    border-radius: 5px;
	    margin: 5px auto;
	    padding: 15px 20px 14px;
	}
}
.box-login-description {
	font-size: 14px;
	line-height: 25px;
	h2 {
		font-size: 18px;
		font-weight: 400;
	}
}
.mgnT30 {
	margin-top: 30px;
}
.mgnB30 {
	margin-bottom: 30px;
}
.fnt-14 {
	font-size: 14px;
}
.title-login {
	font-size: 18px;
    font-weight: 400;
}
.lh-33 {
	line-height: 33px;
}
.colorOrange {
    text-decoration: underline;
    color: #ed9c26;
}
.entryBox {
	padding: 30px 20px;
	font-size: 14px;
	h2 {
		font-size: 18px;
		font-weight: 400;
	}
	&.pd-30 {
		padding: 30px;
	}
}

@media (max-width: 768px) {
    .box-white .box-row:not(:last-child) .box-input:first-child {
	    border-bottom: none;
	}
	.pc {
	    display: none !important;
	}
	.mgnT30 {
		margin-top: 20px;
	}
	.mgnB30 {
		margin-bottom: 20px;
	}
}

@media only screen and (min-width: 769px) {
	.sp {
	    display: none !important;
	}
}
